.snk-error-page {
  padding-top: max(env(safe-area-inset-top), 12px);
  padding-right: max(env(safe-area-inset-right), 12px);
  padding-bottom: max(env(safe-area-inset-bottom), 12px);
  padding-left: max(env(safe-area-inset-left), 12px);
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  &-con {
    margin: auto;
    max-width: 340px;
  }

  .snk-btn {
    width: 200px;
    margin-top: 24px;
  }

  &-h1 {
    font-weight: $cerebri-heavy;
    font-size: 24px;
  }
  &-p {
    margin-top: 12px;
  }
}
