/*
 * Checkbox
 */

div.checkbox {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: relative;

  input[type="checkbox"] {
    border: 1px solid black;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-fake {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #CCC;
    z-index: 1;
    border-radius: 1px;
    transition: all 100ms;

    svg {
      width: 13px;
      height: 13px;
      display: block;
      polyline {
        stroke: transparent;
      }
    }
  }

  input[type="checkbox"]:focus + .checkbox-fake, input[type="checkbox"]:focus + .checkbox-toggle {
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
  }

  input[type="checkbox"]:hover:not(:disabled) + .checkbox-fake {
    border-color: green;
  }

  input[type="checkbox"]:disabled + .checkbox-fake {
    opacity: 0.5;
  }

  input[type="checkbox"]:checked + .checkbox-fake {
    border-color: green;
    background-color: green;
    svg polyline {
      stroke: white;
    }
  }

  &.toggle {
    height: 24px;
    width: 50px;
  }

  .checkbox-toggle {
    border: 1px solid red;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 100px;
    transition: all 200ms;

    &-knob {
      width: 16px;
      height: 16px;
      background-color: red;
      border-radius: 100%;
      position: absolute;
      font-size: 12px;
      top: 3px;
      left: 3px;
      transition: all 200ms;
      &:before, &:after {
        display: block;
        position: absolute;
        top: 0;
        line-height: 16px;
        transition: opacity 200ms;
      }
      &:before {
        content: 'ON';
        right: 100%;
        margin-right: 2px;
        color: green;
        opacity: 0;
      }
      &:after {
        content: 'OFF';
        left: 100%;
        margin-left: 2px;
        color: red;
        opacity: 1;
      }
    }
  }

  &.off-ok {
    .checkbox-toggle {
      border-color: $slate-500;
      &-knob {
        background-color: $slate-500;
        &:after {
          color: $slate-500;
        }
      }
    }
    .checkbox-toggle {
      border-color: $slate-500;
    }
  }

  input[type="checkbox"]:checked + .checkbox-toggle {
    border-color: green;
    .checkbox-toggle-knob {
      background-color: green;
      left: 29px;
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 0;
      }
    }
  }
}
