.csh {
  $clr-cell-border: $slate-300;
  // padding: 48px;
  color: $slate-700;
  font-size: 16px;

  &-widther {
    max-width: 1248px;
    margin: 0 auto;
    padding: 0 24px;
  }

  &-head {
    background-color: $slate-50;
    .csh-widther {
      @include flexbox(row, center, flex-end);
      gap: 24px;
      padding-top: 24px;
      padding-bottom: 24px;
      flex-wrap: wrap;
    }
    &-title {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 900;
      // color: $emerald-500;
    }
    &-tabs {
      flex: 1;
      gap: 24px;
      @include flexbox(row, center, flex-start);
      a {
        font-weight: 600;
        color: $slate-500;
        &:hover, &.active {
          color: $emerald-600;
        }
      }
    }
    &-controls {
      @include flexbox(row, center, flex-end);
      gap: 6px;
      flex-wrap: wrap;
      &-ts {
        font-size: 12px;
      }
      &-btns {
        @include flexbox(row, center, flex-end);
        gap: 6px;
      }
    }
    // border: 1px solid red;
  }

  &-btn {
    font-size: 16px;
    background-color: $emerald-500;
    border-radius: 3px;
    padding: 6px 12px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: $emerald-600;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &-tab-time {
    // margin-top: 48px;
  }

  &-filters {
    @include flexbox(row, center, flex-start);
    gap: 24px;
    padding: 24px 0;
    max-width: 100%;
    overflow: auto;
    // flex-wrap: wrap;
  }
  &-filter {
    @include flexbox(row, center, flex-start);
    gap: 6px;
  }

  &-period {
    @include flexbox(row, center, flex-end);
    margin-top: 24px;
    font-size: 22px;
    font-weight: 900;
    flex-wrap: wrap;
    // color: $slate-800;
    &.all {
      margin-bottom: 48px;
      font-size: 24px;
    }
    &.nest-1 {
      padding-left: 12px;
      font-size: 20px;
      font-weight: 700;
      // color: $slate-700;
    }
    &.nest-2 {
      padding-left: 24px;
      font-size: 18px;
      font-weight: 700;
      color: $slate-600;
    }
    &-label {
      font-weight: 500;
      font-size: 16px;
      color: $slate-500;
    }
    &-title {
      text-transform: uppercase;
    }
    &-line {
      flex: 1;
      height: 1px;
      background-color: $slate-200;
      margin: 0 48px;
    }
    &-stats {
      @include flexbox(row, center, flex-end);
      text-align: right;
    }
    &-time {
    }
    &-cash {
      width: 180px;
      // text-align: right;
    }
  }

  &-cal {
    &-con {
      margin-bottom: 96px;
    }
  }

  &-weekhead {
    @include flexbox(row, flex-start, flex-start);
    margin-top: 24px;
    color: $slate-400;
    font-size: 14px;
    border-bottom: 1px solid $clr-cell-border;
    &-box {
      flex: 1;
      flex-shrink: 0;
      padding: 4px;
    }
  }

  &-week {
    @include flexbox(row, stretch, stretch);
    // margin-bottom: 4px;
    // gap: 4px;
    border-right: 1px solid $clr-cell-border;
    &-day, &-sum {
      // width: 100px;
      // height: 100px;
      padding: 8px;
      border: 1px solid $clr-cell-border;
      border-width: 0 0 1px 1px;
      flex: 1;
      flex-shrink: 0;
      aspect-ratio: 1 / 1;
      position: relative;
      @include flexbox(column, flex-end, center);
      &.dif-month {
        .csh-week-day-date {
          color: $slate-400;
        }
      }
      &-date {
        position: absolute;
        top: 4px;
        left: 4px;
        font-size: 14px;
        color: $slate-400;
      }
      &-time, &-cash {
        font-size: 18px;
        font-weight: 700;
      }
      &.today {
        background-color: $emerald-50
      }
    }
    &-sum {
      background-color: $slate-50;
    }
  }

  &-login {
    width: 500px;
    border: 1px solid $slate-200;
    margin: 96px auto;
    padding: 48px;
    &-title {
      font-weight: 900;
      font-size: 24px;
    }
    &-pw {
      padding: 12px;
      display: block;
      border: 1px solid $slate-200;
      width: 100%;
      margin: 24px 0;
      border-radius: 3px;
    }
    // border: 1px solid red;
  }

  @media (max-width: 700px) {
    &-widther {
      margin: 0 12px;
      padding: 0;
      // width: 100%;
      // width: 100%;
    }
    &-cal {
      &-con {
        width: 100%;
        overflow: auto;
      }
      min-width: 550px;
    }
    &-week {
      &-day, &-sum {
        padding: 4px;
        &-time, &-cash {
          font-size: 14px;
        }
      }
    }
    &-period {
      display: block;
      &-line {
        display: none;
      }
      &-stats {
        margin-top: 6px;
        // text-align: right;
      }
      &-cash {
        // width: unset;
      }
    }
  }



  &-invs {
    width: 100%;
    // cell-spacing: 0;
    th, td {
      text-align: left;
      padding: 6px 12px;
      &.right {
        text-align: right;
      }
    }
    &-status {
      display: inline-block;
      padding: 4px 8px;
      background-color: $slate-400;
      border-radius: 100px;
      font-size: 12px;
      font-weight: 700;
      color: white;
      &.paid {
        background-color: $emerald-500;
      }
      &.open {
        background-color: $blue-500;
      }
      &.overdue {
        background-color: $amber-500;
      }
    }
  }
  &-earn {
    &-head {
      @include flexbox(row, center, flex-start);
      gap: 24px;
      margin-top: 48px;
      margin-bottom: 24px;
    }
    &-heading {
      font-size: 24px;
      font-weight: 900;
      margin: 0;
      text-transform: uppercase;
    }
    &-cols {
      @include flexbox(row, flex-start, flex-start);
      gap: 48px;
      margin-top: 48px;
      &-col {
        flex: 1;
        flex-shrink: 0;
      }
    }
    &-tax {
      &-table {
        th, td {
          text-align: right;
          padding: 6px 12px;
        }
      }
    }
    &-bds {
      @include flexbox(column, flex-start, flex-start);
      gap: 48px;
      &-table {
        width: 100%;
        th, td {
          text-align: left;
          padding: 6px 12px;
          &.right {
            text-align: right;
          }
        }
        tbody tr:last-child {
          th, td {
            padding-bottom: 24px;
          }
        }
      }
    }
    &-tax-modal {
      .modal-box {
        padding: 48px;
        width: 100%;
        max-width: 400px;
      }
      &-field {
        margin-top: 24px;
      }
      &-actions {
        margin-top: 48px;
        @include flexbox(row, flex-start, flex-end);
      }
    }
  }




  &-checkbox {
    input[type="checkbox"]:checked + .checkbox-toggle {
      border-color: $emerald-500 !important;
      .checkbox-toggle-knob {
        background-color: $emerald-500 !important;
      }
    }
    input[type="checkbox"]:hover:not(:disabled) + .checkbox-fake {
      border-color: $emerald-500 !important;
    }
    input[type="checkbox"]:checked + .checkbox-fake {
      border-color: $emerald-500 !important;
      background-color: $emerald-500 !important;
    }
    .checkbox-toggle {
      &-knob {
        &:before {
          color: $emerald-500 !important;
        }
      }
    }
  }

}
