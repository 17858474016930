.snk-ldrbrd {
  // @include flexbox(column, stretch, flex-start);

  &-head {
    // padding-bottom: 12px;
    &-padder {
      padding-top: max(env(safe-area-inset-top), 12px);
      padding-left: max(env(safe-area-inset-left), 12px);
      padding-right: max(env(safe-area-inset-right), 12px);
    }
    // border: 1px solid red;
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
      width: 12px;
      // border: 1px solid lime;
      height: 40px;
      bottom: 1px;
      position: absolute;
      z-index: 1;
      transition: all 200ms;
    }
    &:before {
      left: 0;
      background: linear-gradient(to left, transparent, $snk-dark-1);
    }
    &:after {
      right: 0;
      background: linear-gradient(to right, transparent, $snk-dark-1);
    }
  }

  &-filters {
    @include flexbox(row, center, flex-start);
    // border: 1px solid lime;
    overflow: auto;
    margin-top: 6px;
    border-bottom: 1px solid $snk-dark-4;
    padding-left: max(env(safe-area-inset-left), 12px);
    padding-right: max(env(safe-area-inset-right), 12px);
    // margin-left: -12px;

    &-btn {
      display: block;
      color: inherit;
      font-size: 12px;
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      // border: 1px solid black;
      // border-radius: 100px;
      padding: 0 12px;
      color: $snk-mint;
      border-bottom: 1px solid transparent;

      &.active {
        color: $snk-light-2;
        font-weight: $cerebri-bold;
        border-bottom: 1px solid $snk-mint;
      }
    }
  }

  &-loading {
    margin: 48px auto;
    opacity: 0.2;
    width: 36px;
    display: block;
  }

  &-load-p {
    margin: 48px 0;
    text-align: center;
    margin-left: max(env(safe-area-inset-left), 12px);
    margin-right: max(env(safe-area-inset-right), 12px);
  }

  &-con {
    @include flexbox(column, stretch, flex-start);
    padding-bottom: max(env(safe-area-inset-bottom), 12px);
    overflow: auto;
    gap: 6px;
  }
}

.snk-score {
  @include flexbox(row, center, flex-start);
  background-color: $snk-dark-3;
  padding: 12px 0;
  padding-right: 12px;
  padding-left: 12px;

  &.safe-edges {
    padding-right: max(env(safe-area-inset-right), 12px);
    padding-left: max(env(safe-area-inset-left), 12px);
  }

  &-rank {
    font-weight: $cerebri-heavy;
    font-size: 20px;
    width: 60px;
    text-align: right;
    padding-right: 12px;
  }
  &-avatar {
    margin-right: 12px;
  }
  &-name {
    flex: 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-city {
    flex: 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-score {
    font-weight: $cerebri-heavy;
  }
  &-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    &-line1 {
      @include flexbox(row, center, space-between);
      font-size: 16px;
      font-weight: $cerebri-bold;
      gap: 12px;
    }
    &-line2 {
      @include flexbox(row, center, space-between);
      font-size: 11px;
      gap: 12px;
    }
  }
}

.snk-scrolly-page.scrolled {
  .snk-ldrbrd-head {
    &:before {
      background: linear-gradient(to left, transparent, $snk-dark-3);
    }
    &:after {
      background: linear-gradient(to right, transparent, $snk-dark-3);
    }
  }
}
