.modal {
  background-color: rgba(0,0,0,0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: $spacer * 4;
  z-index: 1000;

  .modal-box {
    background-color: white;
    margin: $spacer * 4;
    margin: auto;
    position: relative;

    &-close, &-back {
      width: 32px;
      height: 32px;
      @include flexbox(row, center, center);
      position: absolute;
      top: ($spacer * 2) - 9px;
      right: ($spacer * 2) - 9px;
      color: $slate-600;
      border-radius: 100px;
      cursor: pointer;
      &:hover {
        color: $slate-900;
        background-color: $slate-200;
      }
      svg.icon {
        width: 14px;
        display: block;
        flex-shrink: 0;
      }
    }
    &-back {
      left: ($spacer * 2) - 9px;
      right: unset;
      svg.icon {
        width: 16px;
      }
    }
    &-close {
      svg.icon {
        stroke-width: 2px;
      }
    }
  }

  @include media("<medium") {
    padding: ($spacer * 2) 0;
  }
}

