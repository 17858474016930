.snk-settings {

  &-head {
    padding-top: max(env(safe-area-inset-top), 12px);
    padding-left: max(env(safe-area-inset-left), 12px);
    padding-right: max(env(safe-area-inset-right), 12px);
    padding-bottom: 12px;
  }

  &-body {
    padding-bottom: 48px;
  }

  &-section {
    margin-top: 48px;
    padding-left: max(env(safe-area-inset-left), 12px);
    padding-right: max(env(safe-area-inset-right), 12px);

    &-title {
      text-transform: uppercase;
      font-size: inherit;
      font-weight: $cerebri-regular;
      padding-left: 12px;
    }

    &-btn-group {
      @include flexbox(column, stretch, flex-start);
      margin-top: 6px;
      border-radius: 3px;
      overflow: hidden;
    }

    &-btn {
      @include flexbox(row, center, flex-start);
      background-color: $snk-dark-3;
      color: inherit;
      font-size: inherit;
      height: 44px;
      padding: 0 12px;
      border: none;
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 1px;
      }
      &-caret {
        width: 12px;
        stroke-width: 1.5px;
        margin-right: -6px;
        margin-left: 12px;
      }
      &-label {
        flex: 1;
        text-align: left;
        font-weight: $cerebri-bold;
      }
      &-val {

      }
    }

    &-p {
      padding: 3px 13px;
      font-size: 12px;
      opacity: 0.7;
    }
  }

  &-btns {
    @include flexbox(column, center, center);
    gap: 12px;
    margin: 24px auto;
    .snk-btn {
      width: 200px;
      $snkg-orange: hsl(14,  38%, 61%);
      $snkg-yellow: hsl(48,  38%, 61%);
      $snkg-green:  hsl(110,  38%, 61%);
      $snkg-blue:   hsl(200, 38%, 61%);
      $snkg-purple: hsl(260, 38%, 61%);
      $snkg-pink:   hsl(320, 38%, 61%);
      $snkg-red:    hsl(360, 50%, 50%);

      &.orange { background-color: $snkg-orange; }
      &.yellow { background-color: $snkg-yellow }
      &.green { background-color: $snkg-green }
      &.blue { background-color: $snkg-blue }
      &.purple { background-color: $snkg-purple }
      &.pink { background-color: $snkg-pink }
      &.red { background-color: $snkg-red }
    }
  }
}



.snk-modal-avatar {
  .modal-box {
    max-width: 420px !important;
  }
  &-faces-con {
    @include flexbox(row, center, center);
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
  }

  &-face-btn {
    width: 44px;
    height: 44px;
    padding: 0;
    flex-shrink: 0;
    @include flexbox(row, center, center);
  }

  &-final {
    margin: 24px auto;
    width: 60px;
    height: 60px;
  }
}



.snk-modal-login {
  .modal-box {
    max-width: 400px !important;
  }
  &-pp {
    margin-top: 12px;
  }
}



.snk-modal-city {
  .modal-box {
    max-width: 400px !important;
  }
  &-list {
    max-height: 300px;
    overflow: auto;
    margin: 24px -48px -48px -48px;
    border-radius: 0 0 3px 3px;
    @include flexbox(column, stretch, flex-start);
    &-loading {
      display: block;
      margin: 24px auto;
    }
    &-city {
      border: none;
      margin: 0;
      height: 44px;
      line-height: 44px;
      color: inherit;
      background-color: $snk-dark-1;
      position: relative;
      font-size: inherit;
      text-align: left;
      padding: 0 12px;
      & + .snk-modal-city-list-city {
        margin-top: 1px;
      }
      svg.icon {
        width: 12px;
        stroke-width: 1.5px;
        position: absolute;
        right: 6px;
        top: 50%;
        margin-top: -6px;
        color: inherit;
      }
      // &:last-child {
      //   border-radius: 0 0 3px 3px;
      // }
    }
  }
  &-none {
    padding: 12px;
  }
  &-selected {
    margin-top: 24px;
    font-weight: $cerebri-bold;
    font-size: 16px;
  }
  &-recents {
    margin: 24px auto 12px;
    @include flexbox(row, center, center);
    gap: 6px;
  }
}

.snk-modal-display-name {
  &-chars {
    margin-top: 3px;
    font-size: 12px;
    opacity: 0.7;
  }
}
