
.snk {

  &-play {
    background-color: $snk-dark-1;
    width: 100%;
    height: 100%;
    // transition: all 3000ms;

    @media (orientation: landscape) {
      padding-left: max(env(safe-area-inset-left), 12px);
      padding-right: max(env(safe-area-inset-right), 12px);
      padding-top:  12px;
      padding-bottom:  12px;
    }
    @media (orientation: portrait) {
      padding-top: max(env(safe-area-inset-top), 12px);
      padding-bottom: max(env(safe-area-inset-bottom), 12px);
      padding-left:  12px;
      padding-right:  12px;
    }

    // &:not(.special) {
    //   &.red    { background-color: $snk-red-d7; }
    //   &.orange { background-color: $snk-orange-d7; }
    //   &.yellow { background-color: $snk-yellow-d7; }
    //   &.green  { background-color: $snk-green-d7; }
    //   &.mint   { background-color: $snk-mint-d7; }
    //   &.blue   { background-color: $snk-blue-d7; }
    //   &.indigo { background-color: $snk-indigo-d7; }
    //   &.purple { background-color: $snk-purple-d7; }
    //   &.pink   { background-color: $snk-pink-d7; }
    // }
    &.red    .snk-game-status-pr-bar-progress { background-color: $snk-red; }
    &.orange .snk-game-status-pr-bar-progress { background-color: $snk-orange; }
    &.yellow .snk-game-status-pr-bar-progress { background-color: $snk-yellow; }
    &.green  .snk-game-status-pr-bar-progress { background-color: $snk-green; }
    &.mint   .snk-game-status-pr-bar-progress { background-color: $snk-mint; }
    &.blue   .snk-game-status-pr-bar-progress { background-color: $snk-blue; }
    &.indigo .snk-game-status-pr-bar-progress { background-color: $snk-indigo; }
    &.purple .snk-game-status-pr-bar-progress { background-color: $snk-purple; }
    &.pink   .snk-game-status-pr-bar-progress { background-color: $snk-pink; }
  }

  /*
   *  Game Screen
   */

  &-game {
    width: 100%;
    height: 100%;
    @include flexbox(column, stretch, center);
    &-head {
    }
    &-body {
      overflow: hidden;
      padding: 0 0px 0 0px;
      aspect-ratio: 16 / 9;
    }
    &-board-con3 {
      width: 100%;
      aspect-ratio: 16 / 9;
      max-height: 100%;

      position: relative;
    }
    &-board-con2 {
      height: 100%;
      aspect-ratio: 16 / 9;
      max-width: 100%;
      margin: auto;

      position: absolute;
      left: 0; right: 0;
    }
    &-board-con {
      width: 100%;
      height: 100%;
    }

    @media (orientation: portrait) {
      &-body, &-board-con3, &-board-con2 {
        aspect-ratio: 9/16;
      }
    }
    // very short and wide (iphone landscape)
    @media ((max-height: 500px) and (min-width: 700px)) or ((max-height: 600px) and (min-width: 1000px)) or (min-aspect-ratio: 2/1) {
      @include flexbox(row, stretch, center);
      &-head {
      }
      &-board-con3 {
        width: unset;
        max-height: unset;
        height: 100%;
        max-width: 100%;
        @include flexbox(column, center, center);
      }
      &-board-con2 {
        height: unset;
        max-width: unset;
        width: 100%;
        max-height: 100%;
        position: static;
      }
    }
  }

  &-game-status {
    @include flexbox(row, flex-end, center);
    margin-bottom: 12px;
    gap: 24px;

    &-item {
      text-align: center;
      &-val {
        font-weight: $cerebri-heavy;
        font-size: 30px;
        line-height: 30px;
        height: 30px;
        @include flexbox(row, center, center);
      }
      &-label {
        font-size: 12px;
        &.strong {
          font-weight: $cerebri-heavy;
        }
      }
      &.bar {
        flex: 1;
        max-width: 100px;
        max-height: 100px;
      }
    }
    &-pr-bar {
      background-color: $snk-dark-5;
      width: 100%;
      height: 5px;
      border-radius: 100px;
      flex-shrink: 0;
      overflow: hidden;
      &-progress {
        height: 100%;
        background-color: $snk-green;
        border-radius: 100px;
        transition: all 200ms;
        width: 100%;
        transition: all 1000ms;
        @for $p from 0% through 100% {
          &[data-percentage="#{$p}"] { margin-left: -100% + $p; }
        }
      }
    }

    @media ((max-height: 500px) and (min-width: 700px)) or ((max-height: 600px) and (min-width: 1000px)) or (min-aspect-ratio: 2/1) {
      height: 100%;
      margin-bottom: 0;
      margin-right: 12px;
      @include flexbox(column-reverse, flex-end, center);
      &-item {
        text-align: right;
        &-val {
          justify-content: flex-end;
        }
        &.bar {
          .snk-game-status-item-val {
            height: 100%;
            .snk-game-status-pr-bar {
              height: 100%;
              width: 5px;
              position: relative;
              &-progress {
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                margin-left: 0;
                @for $p from 0% through 100% {
                  &[data-percentage="#{$p}"] { bottom: -100% + $p; }
                }
              }
            }
          }
        }
      }
    }

  }



  /*
   *  Board & Cells Layout
   */

  &-game-board {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(48, 1fr);
    grid-template-columns: repeat(27, 1fr);

    &-cell {
      @for $m from 0 through 50 {
        &[data-m="#{$m}"] { grid-row: ($m + 1) / span 1; }
      }
      @for $n from 0 through 50 {
        &[data-n="#{$n}"] { grid-column: ($n + 1) / span 1; }
      }
    }

    @media (orientation: landscape) {
      grid-template-rows: repeat(27, 1fr);
      grid-template-columns: repeat(48, 1fr);

      &-cell {
        @for $m from 0 through 50 {
          &[data-mr="#{$m}"] { grid-column: ($m + 1) / span 1; }
        }
        @for $n from 0 through 50 {
          &[data-n="#{$n}"] { grid-row: ($n + 1) / span 1; }
        }
      }
    }
  }



  /*
   *  Board & Cells Style
   */

  &-game-board {
    $radius: 3px;
    // background-color: $snk-dark-3;
    background-color: rgba(0,0,0,0.4);
    gap: 1px 1px;
    position: relative;
    border-radius: $radius;
    border-bottom: 1px solid transparent;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 2px 4px rgba(0,0,0,.3), 0 1px 0px 0px rgba(255,255,255,.15);
      box-sizing: border-box;
      border-radius: $radius;
      padding-bottom: 1px;
    }

    &.special {
      .snk-game-board-cell-obj.type-body {
        background-color: #BBB;
        transition: all 200ms;
      }
    }
    &.special.blink {
      .snk-game-board-cell-obj.type-body {
        background-color: rgba(#BBB, 60%);
      }
    }

    &-cell {
      &:nth-child(odd) {
        background-color: rgba(0,0,0,0.15);
      }
      &:nth-child(even) {
        background-color: rgba(0,0,0,0.2);
      }

      &:nth-child(1)       { border-radius: $radius 0 0 0; }
      &:nth-child(27)      { border-radius: 0 $radius 0 0; }
      &:nth-last-child(1)  { border-radius: 0 0 $radius 0; }
      &:nth-last-child(27) { border-radius: 0 0 0 $radius; }
      @media (orientation: landscape) {
        &:nth-child(1)       { border-radius: 0 $radius 0 0; }
        &:nth-child(27)      { border-radius: 0 0 $radius 0; }
        &:nth-last-child(1)  { border-radius: 0 0 0 $radius; }
        &:nth-last-child(27) { border-radius: $radius 0 0 0; }
      }

      &.head {
        .snk-game-board-cell-obj {
          &.color-red    { background-color: $snk-red-d3; }
          &.color-orange { background-color: $snk-orange-d3; }
          &.color-yellow { background-color: $snk-yellow-d3; }
          &.color-green  { background-color: $snk-green-d3; }
          &.color-mint   { background-color: $snk-mint-d3; }
          &.color-blue   { background-color: $snk-blue-d3; }
          &.color-indigo { background-color: $snk-indigo-d3; }
          &.color-purple { background-color: $snk-purple-d3; }
          &.color-pink   { background-color: $snk-pink-d3; }
        }
      }

      &-obj {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: #999;

        border-top: 1px solid rgba(255,255,255,0.4);
        box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.5);
        position: relative;

        &.type-special {
          background-color: #BBB;
          box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba(#BBB, 10%);
        }

        &.color-red  {
          background-color: $snk-red;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-red, 10%); }
        }
        &.color-orange  {
          background-color: $snk-orange;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-orange, 10%); }
        }
        &.color-yellow  {
          background-color: $snk-yellow;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-yellow, 10%); }
        }
        &.color-green  {
          background-color: $snk-green;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-green, 10%); }
        }
        &.color-mint  {
          background-color: $snk-mint;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-mint, 10%); }
        }
        &.color-blue  {
          background-color: $snk-blue;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-blue, 10%); }
        }
        &.color-indigo  {
          background-color: $snk-indigo;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-indigo, 10%); }
        }
        &.color-purple  {
          background-color: $snk-purple;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-purple, 10%); }
        }
        &.color-pink  {
          background-color: $snk-pink;
          &.type-food { box-shadow: 0 0 0 min(1.5vw, 1.5vh) rgba($snk-pink, 10%); }
        }
      }
    }
  }

}

.snk-over {
  .modal-box {
    overflow: hidden;
  }
  .snk-score {
    margin-top: -48px;
    margin-left: -48px;
    margin-right: -48px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(255,255,255,0.03);
    background-color: $snk-dark-2;
  }
}

.snk-over, .snk-paused {
  .snk-modal-p {
    margin-top: 0 !important;
  }
}
