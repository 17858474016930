.snk-landing {
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  max-width: 680px;
  font-size: 20px;
  margin: auto;
  padding: 96px 48px;
  overflow: auto;

  @media (max-width: 500px) {
    padding: 48px 24px;
  }

  &-h1 {
    font-weight: $cerebri-heavy;
    text-transform: uppercase;
    font-size: 42px;
  }

  &-p {
    margin-top: 12px;
  }

  &-actions {
    margin-top: 48px;
    @include flexbox(row, flex-start, flex-start);
    flex-wrap: wrap;
    gap: 24px;
  }
  &-play {
    background-color: $snk-mint;
    color: $snk-light-1 !important;
    border-radius: 3px;
    height: 60px;
    padding: 0 24px;
    transition: all 200ms;
    border: 1px solid $snk-dark-4;
    @include flexbox(column, center, center);
    &-main {
      font-size: 16px;
      font-weight: $cerebri-bold;
    }
    &-sub {
      font-size: 12px;
    }
    &:hover {
      background-color: $snk-mint-d1;
    }
  }
  &-download {
    display: block;
    height: 60px;
    img {
      display: block;
      height: 100%;
    }
  }

  &-fp {
    margin-top: 48px;
    font-size: 14px;
    a {
      color: $snk-mint !important;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
