
.snk-home {
  @include flexbox(column, stretch, center);

  &-top {
    padding-top: env(safe-area-inset-top);
    background-color: rgba(0,0,0,0.15);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }

  &-bottom {
    padding-bottom: max(env(safe-area-inset-bottom), 12px);
    background-color: rgba(0,0,0,0.15);
  }

  &-main {
    flex: 1;
    text-align: center;
    overflow: auto;
    padding-top: 48px;
    padding-bottom: 48px;
    padding-right: max(env(safe-area-inset-right), 12px);
    padding-left: max(env(safe-area-inset-left), 12px);
    display: flex;
    flex-direction: column;
    &-con {
      margin: auto;
      display: flex;
      flex-direction: column;
      & > * {
        margin: auto;
      }
    }
    &-h1 {
      text-align: center;
      font-weight: $cerebri-heavy;
      font-size: 36px;
      text-transform: uppercase;
    }

    &-btns {
      margin-top: 48px;
      @include flexbox(column, center, center);
      gap: 12px;

      .snk-btn {
        width: 200px;
      }
    }

    &-lives {
      margin-top: 12px;
    }
  }

}

.snk-personal {
  position: relative;
  &-user {
    @include flexbox(row, center, center);
    padding-right: max(env(safe-area-inset-right), 12px);
    padding-left: max(env(safe-area-inset-left), 12px);
    margin-top: 12px;
    // font-weight: $cerebri-bold;
    gap: 12px;
    // opacity: 0.7;
    &-name {
      // padding: 0 6px;
    }
  }
  &-rank {
    @include flexbox(row, center, flex-start);
    padding-right: max(env(safe-area-inset-right), 24px);
    padding-left: max(env(safe-area-inset-left), 24px);
    overflow: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    white-space: nowrap;
    max-width: 100%;
    &:before, &:after {
      // border: 1px solid red;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      width: 24px;
      z-index: 1;
    }
    &:before {
      left: 0;
      background: linear-gradient(to left, transparent, $snk-dark-3);
    }
    &:after {
      right: 0;
      background: linear-gradient(to right, transparent, $snk-dark-3);
    }
    &-stats {
      @include flexbox(row, center, flex-start);
      gap: 24px;
      flex-shrink: 0;
      flex-wrap: nowrap;
      margin: auto;
    }
    &-stat {
      // flex-shrink: 0;
      @include flexbox(column, center, center);
      &-val {
        font-weight: $cerebri-heavy;
        font-size: 18px;
        height: 24px;
        @include flexbox(row, center, center);
      }
      &-key {
        font-size: 12px;
        opacity: 0.7;
        max-width: 80px;
        // border: 1px solid red;
        white-space: nowrap;
        overflow: hidden;
        // text-overflow: ellipsis;
        position: relative;
        &:after {
          // border: 1px solid red;
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 68px; // width of parent, minus width of gradient
          height: 100%;
          width: 12px;
          z-index: 1;
          background: linear-gradient(to right, transparent, $snk-dark-3);
        }
      }
    }
    &-avatar {
      margin: 2px 0;
    }
    &-lives-icon {
      width: 18px;
      color: $snk-dark-5;
      margin-right: 2px;
      g {
        fill: $snk-mint;
      }
      &.red g {
        fill: $snk-red;
      }
    }
  }
  &-warn {
    padding-right: max(env(safe-area-inset-right), 24px);
    padding-left: max(env(safe-area-inset-left), 24px);
    padding-top: 12px;
    padding-bottom: 12px;
    @include flexbox(row, center, flex-start);
    &-icon {
      color: $snk-red-u1;
      margin-right: 6px;
      stroke-width: 1.5px;
    }
    &-h3 {
      font-size: inherit;
      font-weight: $cerebri-bold;
    }
  }
  &-lives {
    margin-top: 12px;
    @include flexbox(row, center, center);
    &-icon {
      width: 18px;
      color: $snk-dark-5;
      margin-right: 3px;
      g {
        fill: $snk-mint;
      }
    }
    &-count {
      font-size: 18px;
      line-height: 18px;
      font-weight: $cerebri-heavy;
      margin-right: 4px;
    }
    &-label {
      font-size: 12px;
      line-height: 12px;
      opacity: 0.7;
    }
  }
}
