html.app-dotsnake {

  .snk-layout.platform-web {
    .snk-btn:hover:not(:disabled) {
      background-color: $snk-dark-4;
      &.mint   { background-color: $snk-mint-d2; }
      &.green  { background-color: $snk-green-d2; }
      &.purple { background-color: $snk-purple-d2; }
      &.blue   { background-color: $snk-blue-d2; }
      &.orange { background-color: $snk-orange-d2; }
      &.yellow { background-color: $snk-yellow-d2; }
    }
  }

  .snk {

    &-btn {
      color: $snk-light-1;
      font-weight: $cerebri-bold;
      font-size: 14px;
      padding: 0 12px;
      border-radius: 3px;
      text-align: center;
      height: 44px;
      line-height: 44px;
      transition: all 200ms;
      cursor: pointer;
      background-color: $snk-dark-3;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &.bold {
        font-size: 16px;
        font-weight: $cerebri-heavy;
        text-transform: uppercase;
      }
      &.mint   { background-color: $snk-mint; }
      &.green  { background-color: $snk-green; }
      &.purple { background-color: $snk-purple; }
      &.blue   { background-color: $snk-blue; }
      &.orange { background-color: $snk-orange; }
      &.yellow { background-color: $snk-yellow; }
    }

    &-modal {
      padding-top:    max(env(safe-area-inset-top),    12px);
      padding-right:  max(env(safe-area-inset-right),  12px);
      padding-bottom: max(env(safe-area-inset-bottom), 12px);
      padding-left:   max(env(safe-area-inset-left),   12px);

      .modal-box {
        background-color: $snk-dark-2;
        color: $snk-light-1;
        padding: 48px;
        border-radius: 3px;
        text-align: center;
        max-width: 100%;
        // overflow: hidden;

        &-close, &-back {
          svg.icon {
            color: $snk-light-3;
            // stroke-width: 2px;
          }
          &:hover {
            background-color: rgba($snk-light-1, 30%);
            svg.icon {
              color: $snk-dark-4;
            }         
          }
        }
      }

      &-title {
        font-size: 24px;
        font-weight: $cerebri-heavy;
        text-transform: uppercase;
        text-align: center;

        & + .snk-modal-p {
          margin-top: 12px;
        }
      }

      &-actions {
        width: 100%;
        min-width: 200px;
        @include flexbox(column, stretch, center);
        gap: 12px;
        margin-top: 24px;
        .snk-btn {
          width: 100%;
        }
        &-subtext {
          &.red {
            color: $snk-red;
          }
        }
      }

      &-p {
        &.error {
          color: $snk-red-u2;
        }
        a {
          color: $snk-mint;
        }
      }
    }

    &-checkbox {
      input[type="checkbox"]:checked + .checkbox-toggle {
        border-color: $snk-mint;
        .checkbox-toggle-knob {
          background-color: $snk-mint;
        }
      }
      input[type="checkbox"]:hover:not(:disabled) + .checkbox-fake {
        border-color: $snk-mint;
      }
      input[type="checkbox"]:checked + .checkbox-fake {
        border-color: $snk-mint;
        background-color: $snk-mint;
      }
      .checkbox-toggle {
        border: 1px solid $snk-red;
        &-knob {
          background-color: $snk-red;
          &:before {
            color: $snk-mint;
          }
          &:after {
            color: $snk-red;
          }
        }
      }
    }

    &-avatar {
      border-radius: 200px;
      flex-shrink: 0;
      &-icon {
        width: 100%;
        height: 100%;
        color: $snk-light-1;
      }
    }

    &-input {
      font-family: inherit;
      height: 44px;
      outline: 0;
      padding: 0 12px;
      color: $snk-light-1;
      border: none;
      border-radius: 0;
      background-color: transparent;
      width: 100%;
      margin-top: 12px;
      font-weight: $cerebri-bold;
      font-size: 14px;
      border-bottom: 1px solid $snk-light-3;
      text-align: center;
      &::placeholder {
        color: $snk-light-3;
      }
      &:focus {
        border-bottom: 1px solid $snk-light-1;
      }
    }


    &-layout {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &-scrolly-page {
      @include flexbox(column, stretch, flex-start);
      &.scrolled {
        .snk-scrolly-page-head {
          background-color: $snk-dark-3;
          box-shadow: 0 0 10px rgba(0,0,0,0.5);
        }
      }
      &-head {
        z-index: 1;
        transition: all 200ms;
        &-actions {
          @include flexbox(row, center, flex-start);
          &-spacer {
            flex: 1;
          }
          a {
            font-size: 14px;
            color: $snk-mint;
            // height: 40px;
            // line-height: 40px;
            padding-top: 6px;
            padding-bottom: 12px;
            // border: 1px solid lime;
          }
        }
        &-back {
          @include flexbox(row, center, flex-start);
          // color: $snk-mint;
          // border: 1px solid red;
          padding-right: 12px;
          svg.icon {
            width: 14px;
            margin-right: 3px;
          }
        }
        &-h1 {
          text-transform: uppercase;
          font-weight: $cerebri-heavy;
          font-size: 24px;
          line-height: 24px;
        }
      }
      &-body {
        // width: 100%;
        flex: 1;
        // border: 1px solid lime;
        overflow: auto;
      }
    }

  }

}

.snk-foo {
  &-body {
    @include flexbox(column, center, center);
    gap: 12px;
    // font-size: 18px;
  }
  &-name {
    margin-top: 12px;
    font-weight: $cerebri-bold;
    font-size: 18px;
  }
  .modal-box {
    border-radius: 0 !important;
    width: 258px !important;
  }
}
