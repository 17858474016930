$snk-dark-1: #282C34;
$snk-dark-2: #3B3F45;
$snk-dark-3: #21232A;
$snk-dark-4: #1D1F24;
$snk-dark-5: #191A1F;

$snk-light-1: #F2F2F3;
$snk-light-2: #C4C4C4;
$snk-light-3: #AAA;

$snk-red-u9: #faf0f1;
$snk-red-u8: #f5e0e2;
$snk-red-u7: #f0d1d4;
$snk-red-u6: #ebc2c5;
$snk-red-u5: #e6b3b7;
$snk-red-u4: #e0a3a8;
$snk-red-u3: #db949a;
$snk-red-u2: #d6858b;
$snk-red-u1: #d1757d;
$snk-red: #CC666E;
$snk-red-d1: #b85c63;
$snk-red-d2: #a35258;
$snk-red-d3: #8f474d;
$snk-red-d4: #7a3d42;
$snk-red-d5: #663337;
$snk-red-d6: #52292c;
$snk-red-d7: #3d1f21;
$snk-red-d8: #291416;
$snk-red-d9: #140a0b;

$snk-orange-u9: #faf2f0;
$snk-orange-u8: #f5e6e1;
$snk-orange-u7: #f0d9d2;
$snk-orange-u6: #ebcdc3;
$snk-orange-u5: #e6c0b5;
$snk-orange-u4: #e1b3a6;
$snk-orange-u3: #dca797;
$snk-orange-u2: #d79a88;
$snk-orange-u1: #d28e79;
$snk-orange: #CD816A;
$snk-orange-d1: #b9745f;
$snk-orange-d2: #a46755;
$snk-orange-d3: #905a4a;
$snk-orange-d4: #7b4d40;
$snk-orange-d5: #674135;
$snk-orange-d6: #52342a;
$snk-orange-d7: #3d2720;
$snk-orange-d8: #291a15;
$snk-orange-d9: #140d0b;

$snk-yellow-u9: #fcf9f2;
$snk-yellow-u8: #faf2e5;
$snk-yellow-u7: #f7ecd7;
$snk-yellow-u6: #f5e6ca;
$snk-yellow-u5: #f2e0bd;
$snk-yellow-u4: #efd9b0;
$snk-yellow-u3: #edd3a3;
$snk-yellow-u2: #eacd95;
$snk-yellow-u1: #e8c688;
$snk-yellow: #E5C07B;
$snk-yellow-d1: #cead6f;
$snk-yellow-d2: #b79a62;
$snk-yellow-d3: #a08656;
$snk-yellow-d4: #89734a;
$snk-yellow-d5: #73603e;
$snk-yellow-d6: #5c4d31;
$snk-yellow-d7: #453a25;
$snk-yellow-d8: #2e2619;
$snk-yellow-d9: #17130c;

$snk-green-u9: #f5f9f2;
$snk-green-u8: #eaf3e4;
$snk-green-u7: #e0edd7;
$snk-green-u6: #d6e7c9;
$snk-green-u5: #cce1bc;
$snk-green-u4: #c1dbaf;
$snk-green-u3: #b7d5a1;
$snk-green-u2: #adcf94;
$snk-green-u1: #a2c986;
$snk-green: #98C379;
$snk-green-d1: #89b06d;
$snk-green-d2: #7a9c61;
$snk-green-d3: #6a8955;
$snk-green-d4: #5b7549;
$snk-green-d5: #4c623d;
$snk-green-d6: #3d4e30;
$snk-green-d7: #2e3a24;
$snk-green-d8: #1e2718;
$snk-green-d9: #0f130c;

$snk-mint-u9: #f1f9f5;
$snk-mint-u8: #e4f3ea;
$snk-mint-u7: #d6ece0;
$snk-mint-u6: #c8e6d5;
$snk-mint-u5: #bbe0cb;
$snk-mint-u4: #addac1;
$snk-mint-u3: #9fd4b6;
$snk-mint-u2: #91cdac;
$snk-mint-u1: #84c7a1;
$snk-mint: #76C197;
$snk-mint-d1: #6aae88;
$snk-mint-d2: #5e9a79;
$snk-mint-d3: #53876a;
$snk-mint-d4: #47745b;
$snk-mint-d5: #3b614c;
$snk-mint-d6: #2f4d3c;
$snk-mint-d7: #233a2d;
$snk-mint-d8: #18271e;
$snk-mint-d9: #0c130f;

$snk-blue-u9: #f0f5fa;
$snk-blue-u8: #e1ebf5;
$snk-blue-u7: #d2e1f0;
$snk-blue-u6: #c3d7eb;
$snk-blue-u5: #b5cde6;
$snk-blue-u4: #a6c2e1;
$snk-blue-u3: #97b8dc;
$snk-blue-u2: #88aed7;
$snk-blue-u1: #79a4d2;
$snk-blue: #6A9ACD;
$snk-blue-d1: #5f8bb9;
$snk-blue-d2: #557ba4;
$snk-blue-d3: #4a6c90;
$snk-blue-d4: #405c7b;
$snk-blue-d5: #354d67;
$snk-blue-d6: #2a3e52;
$snk-blue-d7: #202e3d;
$snk-blue-d8: #151f29;
$snk-blue-d9: #0b0f14;

$snk-indigo-u9: #eff0f8;
$snk-indigo-u8: #dfe0f1;
$snk-indigo-u7: #cfd1ea;
$snk-indigo-u6: #bfc1e3;
$snk-indigo-u5: #afb2dc;
$snk-indigo-u4: #9fa2d5;
$snk-indigo-u3: #8f93ce;
$snk-indigo-u2: #7f83c7;
$snk-indigo-u1: #6f74c0;
$snk-indigo: #5F64B9;
$snk-indigo-d1: #565aa7;
$snk-indigo-d2: #4c5094;
$snk-indigo-d3: #434682;
$snk-indigo-d4: #393c6f;
$snk-indigo-d5: #30325d;
$snk-indigo-d6: #26284a;
$snk-indigo-d7: #1c1e37;
$snk-indigo-d8: #131425;
$snk-indigo-d9: #090a12;

$snk-purple-u9: #f6f2f8;
$snk-purple-u8: #ece5f1;
$snk-purple-u7: #e3d8ea;
$snk-purple-u6: #d9cbe3;
$snk-purple-u5: #d0bedd;
$snk-purple-u4: #c7b1d6;
$snk-purple-u3: #bda4cf;
$snk-purple-u2: #b497c8;
$snk-purple-u1: #aa8ac1;
$snk-purple: #A17DBA;
$snk-purple-d1: #9171a7;
$snk-purple-d2: #816495;
$snk-purple-d3: #715882;
$snk-purple-d4: #614b70;
$snk-purple-d5: #513f5d;
$snk-purple-d6: #40324a;
$snk-purple-d7: #302538;
$snk-purple-d8: #201925;
$snk-purple-d9: #100c13;

$snk-pink-u9: #f9f1f7;
$snk-pink-u8: #f3e3ee;
$snk-pink-u7: #edd6e6;
$snk-pink-u6: #e7c8dd;
$snk-pink-u5: #e1bad5;
$snk-pink-u4: #daaccc;
$snk-pink-u3: #d49ec4;
$snk-pink-u2: #ce91bb;
$snk-pink-u1: #c883b3;
$snk-pink: #C275AA;
$snk-pink-d1: #af6999;
$snk-pink-d2: #9b5e88;
$snk-pink-d3: #885277;
$snk-pink-d4: #744666;
$snk-pink-d5: #613b55;
$snk-pink-d6: #4e2f44;
$snk-pink-d7: #3a2333;
$snk-pink-d8: #271722;
$snk-pink-d9: #130c11;

html.app-tybro body {
  background-color: $snk-dark-4;
}

.tybro {

  &-btn {
    background-color: transparent;
    color: $snk-mint;
    font-size: 24px;
    padding: 12px 24px;
    cursor: pointer;
    transition: all 300ms;
    @include flexbox(row, center, flex-start);
    &:hover {
      background-color: $snk-dark-4;
    }
    svg.icon {
      width: 16px;
      margin-left: 6px;
      margin-bottom: -2px;
    }
  }

  &-main {
    height: 100vh;
    min-height: 900px;
    background-color: $snk-dark-1;
    background: linear-gradient(to bottom right, $snk-dark-1, $snk-dark-3);
    color: $snk-light-1;
    overflow: hidden;
    padding: 0 48px;

    &-widther {
      max-width: 1200px;
      min-height: 100%;
      margin: 0 auto;
      position: relative;
      @include flexbox(column, stretch, flex-start);
    }
    &-name {
      @include flexbox(row, center, flex-start);
      padding-top: 96px;
      &-logo-con {
        background-color: $snk-dark-5;
        padding: 18px;
        border-radius: 3px;
        margin-right: 48px;
        svg {
          width: 60px;
          fill: $snk-mint;
        }
      }
      &-h1 {
        font-size: 84px;
        line-height: 84px;
        font-weight: $cerebri-heavy;
        text-transform: uppercase;
      }
    }
    &-content-con {
      position: relative;
      flex: 1;
      @include flexbox(row, flex-start, flex-start);
      padding-top: 48px;
      gap: 24px;
      &-spacer {
        width: 200px;
        flex-shrink: 0;
        // border: 1px solid lime;
      }
    }
    &-content {
      &-p {
        font-size: 36px;
        font-weight: $cerebri-bold;
        z-index: 1;
      }
      &-actions {
        @include flexbox(row, flex-start, flex-start);
        gap: 24px;
        margin-top: 48px;
        flex-wrap: wrap;
        button {
          background-color: transparent;
          color: $snk-mint;
          font-size: 24px;
          padding: 12px 24px;
          cursor: pointer;
          transition: all 300ms;
          white-space: nowrap;
          @include flexbox(row, center, flex-start);
          &:hover {
            background-color: $snk-dark-4;
          }
          svg.icon {
            width: 16px;
            margin-left: 6px;
          }
        }
      }
    }
    &-ill {
      position: absolute;
      right: -200px;
      bottom: -12px;
      display: block;
      width: 652px;
    }

    @media (max-width: 1100px) {
      height: unset;
      &-content-con {
        min-height: 0;
        padding-top: 48px;
        &-spacer {
          display: none;
        }
      }
      &-content {
        &-p {
          max-width: unset;
        }
      }
      &-ill {
        position: static;
        right: unset;
        bottom: unset;
        margin: 0 auto;
        margin-top: 96px;
        margin-bottom: -12px;
      }
    }
    @media (max-width: 780px) {
      padding: 0 24px;
      &-name {
        padding-top: 48px;
        @include flexbox(column, flex-start, flex-start);
        &-logo-con {
          margin-bottom: 48px;
        }
      }
    }
  }

  &-snk {
    height: 100vh;
    min-height: 900px;
    background-color: $snk-dark-3;
    color: $snk-light-1;
    overflow: hidden;
    padding: 0 48px;

    &-header {
      @include flexbox(row, center, space-between);
      width: 100%;
      // border: 1px solid red;
    }

    &-widther {
      max-width: 1200px;
      min-height: 100%;
      margin: 0 auto;
      // border: 1px solid lime;
      @include flexbox(column, flex-start, center);
      gap: 48px;
    }

    &-h1 {
      // margin: 48px 0;
      font-weight: $cerebri-heavy;
      font-size: 32px;
      text-transform: uppercase;
    }

    &-imgs-con {
      width: 100%;
      height: 650px;
      overflow: auto;
      position: relative;
    }
    &-imgs {
      @include flexbox(row, flex-start, flex-start);
      gap: 24px;
      height: 100%;

      img {
        aspect-ratio: 585 / 1266;
        display: block;
        width: 300px;
        height: 649px;
        border-radius: 20px;
        // max-height: 80vh;
        // min-width: 0;
      }
    }

    @media (max-width: 780px) {
      padding: 0 24px;
      &-header {
        @include flexbox(column, flex-start, flex-start);
        .tybro-btn {
          margin-top: 24px;
        }
      }
    }
  }

  &-contact {
    min-height: 100vh;
    // min-height: 900px;
    background-color: $snk-dark-1;
    color: $snk-light-1;
    overflow: hidden;
    padding: 0 48px;

    &-widther {
      max-width: 1200px;
      min-height: 100vh;
      margin: 0 auto;
      padding: 48px 0;
      // border: 1px solid lime;
      @include flexbox(column, flex-start, flex-start);
      gap: 48px;
    }

    &-form {
      width: 100%;
      max-width: 400px;
      display: block;
      margin: auto;

      &-h1 {
        font-weight: $cerebri-heavy;
        font-size: 32px;
        text-transform: uppercase;
        margin-bottom: 48px;
      }
      &-thankyou {
        color: $snk-light-1;
        font-size: 18px;
        text-align: center;
      }

      label {
        // padding-left: 13px;
        font-size: 14px;
        color: $snk-light-2;
        text-transform: uppercase;
        margin-bottom: 48px;
        display: block;
        &:focus-within {
          color: $snk-mint;
        }
      }
      &-input {
        display: block;
        width: 100%;
        padding: 12px 0;
        border: none;
        border-bottom: 1px solid $snk-light-2;
        font-weight: $cerebri-bold;
        background-color: transparent;
        color: $snk-light-1;
        font-size: 16px;
        border-radius: 0px;
        // margin-bottom: 24px;
        &::placeholder {
          color: rgba($snk-light-1, 0.3);
        }
        &:focus {
          outline: none;
          border-color: $snk-mint;
        }
      }
      #contact-submit {
        display: block;
        width: 100%;
        padding: 12px;
        background-color: $snk-mint;
        color: $snk-light-1;
        font-size: 16px;
        font-weight: $cerebri-bold;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        margin-top: 48px;
        &:hover {
          background-color: $snk-mint-d2;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
    }

    @media (max-width: 480px) {
      padding: 0 24px;
    }
  }

  &-footer {
    background-color: $snk-dark-4;
    color: $snk-light-2;
    padding: 0 48px;
    font-size: 14px;
    &-widther {
      max-width: 1200px;
      margin: 0 auto;
      gap: 24px;
      @include flexbox(row, center, flex-start);
      flex-wrap: wrap;
      padding: 24px 0;
      a {
        color: $snk-mint;
      }
    }
    @media (max-width: 780px) {
      padding: 0 24px;
    }
  }

}
