@import 'include-media/dist/include-media';

@import './vars'; // needs to come first
@import './mixins';
@import './reset';

.widther {
  max-width: 1440px;
  padding: 0 ($spacer * 4);
  margin: 0 auto;

  @include media("<xl") {
    padding: 0 ($spacer * 3);
  }
  @include media("<medium") {
    padding: 0 ($spacer * 2);
  }
}

@-webkit-keyframes spin {
  0%  {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
.spin {
  animation: spin 1.5s infinite linear;
}

.icon {
  width: 24px;
}

.icon.caret {
  transition: all 150ms;
  &.up { transform: rotate(180deg); }
  &.right { transform: rotate(-90deg); }
  &.left { transform: rotate(90deg); }
}

@import './common/modal';
@import './common/checkbox';
@import './dotsnake/index';
@import './tybro/index';
@import './cash';
