.snk-instr {
  @include flexbox(column, stretch, flex-start);

  &-body {
    height: 100%;
    display: flex;
  }

  &-items {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    gap: 72px;
    padding: 72px;
    margin: auto;
    // height: 100%;
  }

  &-item {
    font-size: 18px;
    text-align: center;
    max-width: 400px;
    margin: auto;

    &-icon {
      width: 54px;
    }

    &.swipe, &.swipe2 {
      .snk-instr-item-icon {
        width: 60px;
      }
    }
  }

  &-grid {
    padding: 12px;
    &-row {
      @include flexbox(row, center, center);
      &:nth-child(odd) .snk-instr-grid-cell {
        &:nth-child(odd) {
          background-color: rgba(0,0,0,0.2);
        }
        &:nth-child(even) {
          background-color: rgba(0,0,0,0.15);
        }        
      }
      &:not(:last-child) .snk-instr-grid-cell {
        border-bottom: none;
      }
    }
    &-cell {
      width: 20px;
      height: 20px;
      border: 1px solid rgba(0,0,0,0.25);
      position: relative;
      &:nth-child(odd) {
        background-color: rgba(0,0,0,0.15);
      }
      &:nth-child(even) {
        background-color: rgba(0,0,0,0.2);
      }
      &:not(:last-child) {
        border-right: none;
      }
      &.dot:before, &.food:before, &.special:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        border-top: 1px solid rgba(255,255,255,0.4);
        box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.5);
        box-sizing: border-box;
      }
      &.dot:before {
        background-color: $snk-mint;
      }
      &.dot.head:before {
        background-color: $snk-mint-d3;
      }
      &.food:before {
        background-color: $snk-blue;
        box-shadow: 0 0 0 8px rgba($snk-blue, 10%);
      }
      &.special:before {
        background-color: #BBB;
        box-shadow: 0 0 0 8px rgba(#BBB, 10%);
      }
    }
  }

}

